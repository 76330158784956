import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { FaHome, FaBook, FaFlask, FaBullhorn, FaCalendarAlt, FaEnvelope, FaEdit, FaChevronDown, FaChevronUp, FaSignOutAlt, FaEye } from 'react-icons/fa';
import './Sidebar.css';
import schoolLogo from './schoollogo.png'; 
import Avatar from '@mui/material/Avatar';

function TeacherSidebar({ onSelect }) {
  const [menuItems, setMenuItems] = useState([]);
  const [expandedItems, setExpandedItems] = useState({});
  const [submenuItems, setSubmenuItems] = useState({});
  const navigate = useNavigate(); // Use useNavigate for navigation

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/ExclusivePermission/Module', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
          body: JSON.stringify({})
        });
        const data = await response.json();
        setMenuItems(data);
        
        // Initialize expanded items state
        const initialExpandedItems = {};
        data.forEach(item => {
          initialExpandedItems[item.moduleId] = false;
        });
        setExpandedItems(initialExpandedItems);
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    };

    fetchMenuItems();
  }, []);

  const handleToggle = async (id) => {
    setExpandedItems(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));

    if (!expandedItems[id]) {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/ExclusivePermission/Form_Authorizer', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
          body: JSON.stringify({ moduleId: id })
        });
        const data = await response.json();
        setSubmenuItems(prevState => ({
          ...prevState,
          [id]: data
        }));
      } catch (error) {
        console.error('Error fetching submenu items:', error);
      }
    }
  };

  const handleLogout = () => {
    sessionStorage.clear(); // Clear session storage or any authentication tokens
    navigate('/'); // Redirect to the login page
  };

  const iconMap = {
    Dashboard: <FaHome />,
  
    Assignment: <FaEdit />,
    Attendance: <FaCalendarAlt />,
    Examination: <FaCalendarAlt />,
    'Study Material': <FaBook />, 
    Lesson: <FaBook />,
    Topic: <FaBook />,
    Logout: <FaSignOutAlt />,
    'Assignment Create': <FaBook />,
    assignmentview : <FaBook />,
    'Exam': <FaEnvelope />,
    'View Announcements': <FaEye /> // Add icon for View Announcements
  };

  const logo = sessionStorage.getItem('clientLogo');
  const OrgName = sessionStorage.getItem('organizationName').replace(/['"]+/g, '');



  return (
    <div className="sidebar">
      <div className="sidebar-header">
      <Avatar 
      alt={OrgName} 
      src={`https://arizshad-002-site5.ktempurl.com${logo.replace('~', '')}`} 
      className="school-logo"
    />
        <h2>{OrgName}</h2>
      </div>
      <ul>
        <li>
          <Link to="/teachersDashboard" onClick={() => onSelect('Dashboard')}>
            <FaHome className="icon" />
            <span className="menu-text">Dashboard</span>
          </Link>
        </li>
        {menuItems.map(menuItem => (
          <li key={menuItem.moduleId}>
            <span className="menu-heading" onClick={() => handleToggle(menuItem.moduleId)}>
              <span className="icon">{iconMap[menuItem.moduleName]}</span>
              <span className="menu-text">{menuItem.moduleName}</span>
              {expandedItems[menuItem.moduleId] ? <FaChevronUp className="dropdown-icon" /> : <FaChevronDown className="dropdown-icon" />}
            </span>
            <ul className={`submenu ${expandedItems[menuItem.moduleId] ? 'submenu-show' : ''}`}>
              {submenuItems[menuItem.moduleId] && submenuItems[menuItem.moduleId].map(subItem => (
                <li key={subItem.formId}>
                  <Link to={`/${subItem.formName.toLowerCase().replace(/\s+/g,'')}`} onClick={() => onSelect(subItem.formName)}>
                    <span className="icon">{iconMap[subItem.formName]}</span> {subItem.formName}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
       <li>
          <Link to="/view-announcements" onClick={() => onSelect('View Announcements')}>
            <FaEye className="icon" />
            <span className="menu-text">View Announcements</span>
          </Link>
        </li>
       
        <li onClick={handleLogout} className="menu-heading">
          <span className="icon">{iconMap['Logout']}</span>
          <span className="menu-text" onClick={handleLogout} >Logout</span>
        </li>
      </ul>
    </div>
  );
}

export default TeacherSidebar;
