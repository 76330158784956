import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './DueCard.css';
import dueImage from './due.png'; 

const DueCard = () => {
  const [totalDues, setTotalDues] = useState(0);

  useEffect(() => {
    const fetchDues = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/DuesRecord_All', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
          body: JSON.stringify({
            sessionId: 0,
          }),
        });
        const data = await response.json();
        setTotalDues(data.totalDuesAmount);
      } catch (error) {
        console.error('Error fetching dues:', error);
      }
    };

    fetchDues();
  }, []);

  return (
    <div className="due-card">
      <div className="due-card-inner">
        <div className="due-card-front">
          <img src={dueImage} alt="Due" />
          <h3>Due List</h3>
          <Link to="/due-details" className="total-due-link">
            Total: ₹{totalDues}
          </Link>
        </div>
        <div className="due-card-back">
       
        </div>
      </div>
    </div>
  );
};

export default DueCard;
