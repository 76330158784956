import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './SubjectDetail.css';

const SubjectDetail = () => {
  const [subjectDetails, setSubjectDetails] = useState([]);
  const { subjectName } = useParams();

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/Dashboard_SubjectCard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
      });
      const data = await response.json();
      setSubjectDetails(data.list);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  };

  return (
    <div className="subject-detailing">
      <h1>Subject Details for {subjectName}</h1>
      <table>
        <thead>
          <tr>
            <th>Class</th>
            <th>Section</th>
            <th>Subject</th>
          </tr>
        </thead>
        <tbody>
          {subjectDetails.map((detail, index) => (
            <tr key={index}>
              <td>{detail.className}</td>
              <td>{detail.secrionName}</td>
              <td>{detail.subjectName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubjectDetail;
