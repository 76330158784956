import React, { useState, useEffect } from 'react';
import './AttendanceForm.css'; // Import your CSS file for styling

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const parseDate = (dateStr) => {
  const [day, month, year] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day);
};

function AttendanceForm() {
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date())); // Initialize with the current date
  const [studentList, setStudentList] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [classes, setClasses] = useState([]);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    fetchTeachers();
  }, []);

  useEffect(() => {
    if (selectedTeacher) {
      fetchClasses(selectedTeacher);
    }
  }, [selectedTeacher]);

  useEffect(() => {
    if (selectedTeacher && selectedClass) {
      fetchSections(selectedTeacher, selectedClass);
    }
  }, [selectedTeacher, selectedClass]);

  useEffect(() => {
    fetchStudents(selectedClass, selectedSection);
  }, [selectedClass, selectedSection]);

  const fetchTeachers = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetTeacher', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
      });
      const data = await response.json();
      setTeachers(data);
    } catch (error) {
      console.error('Error fetching teachers:', error);
    }
  };

  const fetchClasses = async (teacherId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetClass', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ teacherId }),
      });
      const data = await response.json();
      setClasses(data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };

  const fetchSections = async (teacherId, classId) => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlSection_clsId', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({ teacherId, classId }),
      });
      const data = await response.json();
      setSections(data);
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  const fetchStudents = async (classId, sectionId) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/Attendance/GetStudentById`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ classId, sectionId }),
      });

      if (!response.ok) {
        throw new Error(`Error fetching subjects: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        return; // Exit the function if the record is not found
      }
      setStudentList(data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTeacherChange = (e) => setSelectedTeacher(e.target.value);
  const handleClassChange = (e) => setSelectedClass(e.target.value);
  const handleSectionChange = (e) => setSelectedSection(e.target.value);
  const handleDateChange = (e) => {
    const dateValue = e.target.value;
    setSelectedDate(dateValue ? formatDate(new Date(dateValue)) : formatDate(new Date()));
  };

  const handleAttendanceChange = (id, status) => {
    setStudentList(prevList =>
      prevList.map(student =>
        student.studentId === id ? { ...student, attendance: status } : student
      )
    );
  };

  const handleSubmitAttendance = async () => {
    const attendanceData = studentList.map((student) => ({
      studentId: student.studentId,
      isAbsent: student.attendance === 'Absent',
      isPresent: student.attendance === 'Present'
    }));
    const formattedDate = selectedDate.split('-').reverse().join('/');


    const payload = {
      classId: selectedClass,
      sectionId: selectedSection,
      attendenceDate: formattedDate,
      attendence: attendanceData
    };

    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/SaveAttendance', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error saving attendance: ${response.status}`);
      }

      const result = await response.json();
      console.log('Attendance saved:', result);
      alert('Attendance has been marked.');
    } catch (error) {
      console.error('Error saving attendance:', error);
      alert('Failed to save attendance.');
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h2>Attendance Form</h2>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="teacher">Teacher</label>
            <select
              value={selectedTeacher}
              onChange={handleTeacherChange}
            >
              <option value="">Select Teacher</option>
              {teachers.map((teacher) => (
                <option key={teacher.employeeId} value={teacher.employeeId}>
                  {teacher.employeeName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="class">Class</label>
            <select
              value={selectedClass}
              onChange={handleClassChange}
            >
              <option value="">Select Class</option>
              {classes.map((cls) => (
                <option key={cls.classId} value={cls.classId}>
                  {cls.className}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="section">Section</label>
            <select
              value={selectedSection}
              onChange={handleSectionChange}
            >
              <option value="">Select Section</option>
              {sections.map((sec) => (
                <option key={sec.sectionId} value={sec.sectionId}>
                  {sec.sectionName}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="date">Date</label>
            <input
              type="date"
              id="date"
              name="Date"
              onChange={handleDateChange}
              value={selectedDate ? parseDate(selectedDate).toISOString().split('T')[0] : ''}
              required
            />
          </div>
        </div>
        {studentList.length > 0 && (
          <div className="student-list">
            <h2>Student List</h2>
            <table>
              <thead>
                <tr>
                  <th>Serial</th>
                  <th>Student Name</th>
                  <th>Roll No</th>
                  <th>Mobile No</th>
                  <th>Attendance</th>
                </tr>
              </thead>
              <tbody>
                {studentList.map((student, index) => (
                  <tr key={student.studentId}>
                    <td>{index + 1}</td>
                    <td>{student.studentName}</td>
                    <td>{student.rollNo}</td>
                    <td>{student.mobileNo}</td>
                    <td>
                      <div className="attendance-options">
                        <label>
                          <input
                            type="radio"
                            name={`attendance-${student.studentId}`}
                            value="Present"
                            checked={student.attendance === 'Present'}
                            onChange={() => handleAttendanceChange(student.studentId, 'Present')}
                          />
                          Present
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`attendance-${student.studentId}`}
                            value="Absent"
                            checked={student.attendance === 'Absent'}
                            onChange={() => handleAttendanceChange(student.studentId, 'Absent')}
                          />
                          Absent
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`attendance-${student.studentId}`}
                            value="Week Off"
                            checked={student.attendance === 'Week Off'}
                            onChange={() => handleAttendanceChange(student.studentId, 'Week Off')}
                          />
                          Week Off
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="form-actions">
              <button onClick={handleSubmitAttendance}>Submit Attendance</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AttendanceForm;
