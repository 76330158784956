import React, { useState, useEffect } from 'react';
import './Lessons.css';
import { useNavigate } from 'react-router-dom';

function Lessons() {
  const [searchTerm, setSearchTerm] = useState('');
  const [lessons, setLessons] = useState([]);
  const [error, setError] = useState('');
  let navigate = useNavigate();

  // Fetch lessons from the API when the component mounts
  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher_Lessoon/GetLesson_Teacher', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
        });
        

        if (!response.ok) {
          throw new Error(`Error fetching religion data: ${response.status}`);
      }
        const data = await response.json();
        
        if (data.data === null && data.msg === "Record Not Found") {
          setError('Record Not Found');
          return; // Exit the function if the record is not found
        }
        setLessons(data);
      } catch (error) {
        console.error('Error fetching lessons:', error);
        setError('Error fetching lessons.');
      }
    };

    fetchLessons();
  }, []);

  const handleAddLessonClick = () => {
    navigate('/addlesson');
  };

  const handleEditLesson = (lessonId) => {
    navigate(`/editlesson/${lessonId}`);
  };

  const handleDeleteLesson = async (lessonId) => {
    const confirmed = window.confirm("Are you sure you want to delete this lesson?");
    
    if (confirmed) {
      try {
        const response = await fetch(`https://arizshad-002-site5.ktempurl.com/api/Teacher_Lessoon/Id?LessonId=${lessonId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
        });

        if (response.ok) {
          setLessons(lessons.filter(lesson => lesson.lessonId !== lessonId));
        } else {
          setError('Failed to delete lesson.');
        }
      } catch (error) {
        console.error('Error deleting lesson:', error);
        setError('Error deleting lesson.');
      }
    }
  };

  return (
    <div className="lessons-container">
      <div className="lessons-header">
        <div className="lessons-search-container">
          <input 
            type="text" 
            placeholder="Search Lessons..." 
            value={searchTerm} 
            onChange={(e) => setSearchTerm(e.target.value)}
            className="lessons-search-box"
          />
          <button className="lessons-add-button" onClick={handleAddLessonClick}>
            Add Lesson
          </button>
        </div>
      </div>
      {error && <p className="lessons-error-message">{error}</p>}
      <table className="lessons-table">
        <thead>
          <tr>
            <th>Lesson Name</th>
            <th>Class</th>
            <th>Content</th>
            <th>File</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {lessons.filter(lesson =>
            lesson.lessonName.toLowerCase().includes(searchTerm.toLowerCase())
          ).map((lesson, index) => (
            <tr key={index}>
              <td>{lesson.lessonName}</td>
              <td>{lesson.className}</td>
              <td>{lesson.lessonContent}</td>
              <td>
                <button
                  className="lessons-action-button view"
                  onClick={() => window.open(`https://arizshad-002-site5.ktempurl.com/${lesson.lessionPdfUrl}`, '_blank')}
                >
                  View
                </button>
              </td>
              <td>
                <button className="lessons-action-button edit" onClick={() => handleEditLesson(lesson.lessonId)}>Edit</button>
                <button
                  className="lessons-action-button delete"
                  onClick={() => handleDeleteLesson(lesson.lessonId)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Lessons;
