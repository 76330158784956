import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AssignmentCard.css';
import assignmentImage from './assignment.png';

const AssignmentCard = () => {
  const [submittedAssignments, setSubmittedAssignments] = useState(0);
  const [pendingAssignments, setPendingAssignments] = useState(0);
  const [progress, setProgress] = useState('0.00%');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssignmentData = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/Dashboard_AssignmentCard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
          body: JSON.stringify({
            teacherId: sessionStorage.getItem('employeeId'), // Assuming teacherId is needed, adjust as necessary
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data && data.list && data.list.length > 0) {
            const assignmentData = data.list[0];
            setSubmittedAssignments(assignmentData.assignmentSubmittedCount);
            setPendingAssignments(assignmentData.assignmentNotSubmittedCount);
            setProgress(assignmentData.progress);
          }
        } else {
          console.error('Failed to fetch assignment data');
        }
      } catch (error) {
        console.error('Error fetching assignment data:', error);
      }
    };

    fetchAssignmentData();
  }, []);

  const totalAssignments = submittedAssignments + pendingAssignments;
  const percentage = totalAssignments > 0 
    ? (submittedAssignments / totalAssignments) * 100 
    : 0;
  const percentageText = `${percentage.toFixed(2)}%`;

  const handleSubmittedClick = () => {
    navigate('/submitted-assignments');
  };

  const handlePendingClick = () => {
    navigate('/pending-assignments');
  };

  return (
    <div className="assignment-card2">
      <div className="assignment-card-inner">
        <div className="assignment-card-front">
          <img src={assignmentImage} alt="Assignment" />
          <h2>Assignment Progress</h2>
        </div>

        <div className="assignment-card-back">
          <div className="assignment-detail">
            <p>
              <strong>
                <span className="clickable-text" onClick={handleSubmittedClick} style={{cursor: 'pointer'}}>
                  Submitted Assignments:
                </span>
              </strong> {submittedAssignments}
            </p>
            <p>
              <strong>
                <span className="clickable-text" onClick={handlePendingClick} style={{cursor: 'pointer'}}>
                  Pending Assignments:
                </span>
              </strong> {pendingAssignments}
            </p>
            <p><strong>Progress:</strong> {progress}</p>
            <div className="progress-bar">
              <div className="progress-bar-fill" style={{ width: percentageText }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentCard;
