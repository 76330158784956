import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import './Announcements.css';

import ReactQuill from 'react-quill'; // Text editor component
import 'react-quill/dist/quill.snow.css';

const Announcement = () => {
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [error, setError] = useState('');
  const [classOptions, setClassOptions] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  // Fetch user options when component mounts
  useEffect(() => {
    const fetchUserOptions = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Anouncement/GetUserGroup', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
          body: JSON.stringify({
            teacherId: sessionStorage.getItem('employeeId'),
          }),
        });
        const data = await response.json();
        const options = data.list.map((user) => ({
          value: user.userGroupId,
          label: user.userGroupname,
        }));
        setUserOptions(options);
      } catch (error) {
        console.error('Error fetching user options:', error);
      }
    };

    fetchUserOptions();
  }, []);

  // Fetch classes when component mounts
  useEffect(() => {
    const fetchClassOptions = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/GetClass', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
          body: JSON.stringify({
            teacherId: sessionStorage.getItem('employeeId'),
          }),
        });
        const data = await response.json();
        const options = data.map((cls) => ({
          value: cls.classId,
          label: cls.className,
        }));
        setClassOptions(options);
      } catch (error) {
        console.error('Error fetching class options:', error);
      }
    };

    fetchClassOptions();
  }, []);

  // Fetch sections when class selection changes
  useEffect(() => {
    const fetchSectionOptions = async () => {
      if (selectedClasses.length > 0) {
        const classId = selectedClasses[selectedClasses.length - 1].value;

        const payload = {
          teacherId: sessionStorage.getItem('employeeId'),
          classId,
        };

        try {
          const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher/ddlSection_clsId', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: sessionStorage.getItem('token'),
            },
            body: JSON.stringify(payload),
          });
          const data = await response.json();
          const options = data.map((section) => ({
            value: section.sectionId,
            label: section.sectionName,
          }));
          setSectionOptions(options);
        } catch (error) {
          console.error('Error fetching section options:', error);
        }
      } else {
        setSectionOptions([]);
      }
    };

    fetchSectionOptions();
  }, [selectedClasses]);

  const handleSave = async () => {
    if (
      selectedUsers.length === 0 ||
      selectedClasses.length === 0 ||
      !selectedSection ||
      title.trim() === '' ||
      message.trim() === ''
    ) {
      setError('Please fill in all required fields.');
      return;
    }

    // Prepare the payload
    const payload = {
      userGroupId: selectedUsers.map((user) => user.value),
      classId: selectedClasses.map((cls) => cls.value),
      sectionId: selectedSection.value,
      message: message.trim(),
      title: title.trim(),
    };

    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Anouncement/CreateAnouncement', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Failed to create announcement');
      }

      // Handle successful response
      setShowPopup(true);
      setError('');

      setTimeout(() => {
        setShowPopup(false);
      }, 3000);

      handleCancel();
    } catch (error) {
      console.error('Error creating announcement:', error);
      setError('Failed to create announcement. Please try again.');
    }
  };

  const handleCancel = () => {
    setSelectedUsers([]);
    setSelectedClasses([]);
    setSelectedSection(null);
    setMessage('');
    setTitle('');
    setError('');
  };

  return (
    <div className="announcement-forms">
      <h2 className="announcement-header">Create Announcement</h2>

      <div className="dropdown-section-container">
        <div className="dropdown-section">
          <label className="dropdown-label">Select User:</label>
          <Select
            isMulti
            options={userOptions}
            value={selectedUsers}
            onChange={setSelectedUsers}
            className="dropdown"
            placeholder="Select user(s)"
          />
        </div>

        <div className="dropdown-section">
          <label className="dropdown-label">Select Class:</label>
          <Select
            isMulti
            options={classOptions}
            value={selectedClasses}
            onChange={setSelectedClasses}
            className="dropdown"
            placeholder="Select class(es)"
          />
        </div>

        <div className="dropdown-section">
          <label className="dropdown-label">Select Section:</label>
          <Select
            options={sectionOptions}
            value={selectedSection}
            onChange={setSelectedSection}
            className="dropdown"
            placeholder="Select a section"
            isDisabled={selectedClasses.length === 0} // Disable section dropdown if no class is selected
          />
        </div>
      </div>

      <div className="title-section">

        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="title-input"
          placeholder="Enter title here..."
        />
      </div>

      <div className="message-section">
        <ReactQuill
          value={message}
          onChange={setMessage}
          className="message-editor"
          placeholder="Enter message here..."
        />
      </div>

      {error && <div className="error-message">{error}</div>}

      <div className="button-section">
        <button onClick={handleSave} className="save-button">Save</button>
        <button onClick={handleCancel} className="cancel-button">Cancel</button>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            Announcement Saved Successfully!
          </div>
        </div>
      )}
    </div>
  );
};

export default Announcement;
