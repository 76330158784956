import React, { useState, useEffect } from 'react';
import './PendingAssignments.css';

const SubmittedAssignments = () => {
  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    const fetchSubmittedAssignments = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/Dashboard_SubmittedAssignmentList', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
          body: JSON.stringify({
            teacherId: sessionStorage.getItem('employeeId'), // Assuming teacherId is needed, adjust as necessary
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data && data.list) {
            setAssignments(data.list);
          }
        } else {
          console.error('Failed to fetch pending assignments');
        }
      } catch (error) {
        console.error('Error fetching pending assignments:', error);
      }
    };

    fetchSubmittedAssignments();
  }, []);

  return (
    <div>
      <h2>Submitted Assignment</h2>
      <table className="pending-assignments-table">
        <thead>
          <tr>
            <th>Admission No</th>
            <th>Student Name</th>
            <th>Subject Name</th>
            <th>Class Name</th>
            <th>Section Name</th>
          </tr>
        </thead>
        <tbody>
          {assignments.map((assignment, index) => (
            <tr key={index}>
              <td>{assignment.admissionNo}</td>
              <td>{assignment.studentName}</td>
              <td>{assignment.subjectName}</td>
              <td>{assignment.className}</td>
              <td>{assignment.sectionName}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubmittedAssignments;
