import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Subjects.css';
import subjecte from './subjecte.png';

const Subjects = () => {
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/Dashboard_SubjectCard', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
        });
        const data = await response.json();

        // Transform data
        const subjectsMap = {};

        data.list.forEach(item => {
          if (!subjectsMap[item.subjectName]) {
            subjectsMap[item.subjectName] = {
              subjectName: item.subjectName,
              classes: []
            };
          }
          
          const classIndex = subjectsMap[item.subjectName].classes.findIndex(cls => cls.className === item.className);
          
          if (classIndex === -1) {
            subjectsMap[item.subjectName].classes.push({
              className: item.className,
              sections: [item.secrionName]
            });
          } else {
            subjectsMap[item.subjectName].classes[classIndex].sections.push(item.secrionName);
          }
        });

        setSubjects(Object.values(subjectsMap));
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    };

    fetchSubjects();
  }, []);

  return (
    <div className="component-card">
      <div className="component-card-inner">
        <div className="component-card-front">
          <img src={subjecte} alt="subjecte" />
          <h2>Subjects</h2>
        </div>
        <div className="component-card-back">
          {subjects.slice(0, 2).map((subject, index) => (
            <div key={index} className="subject-details">
              <h3>{subject.subjectName}</h3>
              {subject.classes.map((cls, clsIndex) => (
                <div key={clsIndex}>
                  <p><strong>{cls.className}:</strong> Sections: {cls.sections.join(', ')}</p>
                </div>
              ))}
            </div>
          ))}
          {subjects.length > 2 && (
            <div className="subject-details">
              <Link to={`/subject-detail`} className="more-info-link">
                More Info
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subjects;
