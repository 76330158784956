import React from 'react';
import './NoticeCard.css'; 

const NoticeCard = () => {
  return (
    <div className="notice-card">
      <h2>Meeting!</h2>
      <div className="notice-content">
        <p>Please ensure that all teachers submit their lesson plans for the upcoming semester by Friday, August 16th.</p>
        <p>Failure to do so may result in delays in class scheduling and resource allocation.</p>
      </div>
      <div className="notice-details">
        <p className="notice-author">Admin</p>
        <p>Posted on: July 15, 2024</p>
      </div>
    </div>
  );
};

export default NoticeCard;
