import React, { useState, useEffect } from 'react';
import './Topics.css'; 
import { useNavigate } from 'react-router-dom';

function Topics() {
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddTopicCard, setShowAddTopicCard] = useState(false);
  const [showMasterPage, setShowMasterPage] = useState(false); 
  const [selectedClass, setSelectedClass] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [topicHeading, setTopicHeading] = useState('');
  const [topicContent, setTopicContent] = useState('');
  const [topicPath, setTopicPath] = useState(null);
  const [error, setError] = useState('');
  const [topics, setTopics] = useState([]);

  let navigate = useNavigate();

  const handleAddTopicClick = () => {
    navigate('/addtopic') 
  };


  const fetchClasses = async () => {
    try {
      const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Teacher_Topic/GetTopic_Teacher', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: sessionStorage.getItem('token'),
        },
        body: JSON.stringify({
          // teacherId: sessionStorage.getItem('employeeId'),
        }),
      });
      const data = await response.json();
      setTopics(data);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  };



  useEffect(() => {
    fetchClasses();
  }, []);

  const handleEdit = (topicId) => {
   
  navigate(`/edittopic/${topicId}`)
  };

  const handleDelete = async (topicId) => {
    const confirmed = window.confirm("Are you sure you want to delete this lesson?");
    
    if (confirmed) {
      try {
        const response = await fetch(`https://arizshad-002-site5.ktempurl.com/api/Teacher_Topic/Id?TopicId=${topicId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
        });

        if (response.ok) {
          setTopics(topics.filter(topics => topics.topicId !== topicId));
        } else {
          setError('Failed to delete lesson.');
        }
      } catch (error) {
        console.error('Error deleting lesson:', error);
        setError('Error deleting lesson.');
      }
    }
  };

  return (
    <div className="topics-wrapper">
      {!showMasterPage && (
        <>
          <div className="topics-header">
            <div className="topics-search-container">
              <input 
                type="text" 
                placeholder="Search Topics..." 
                value={searchTerm} 
                onChange={(e) => setSearchTerm(e.target.value)}
                className="topics-search-box"
              />
              <button className="topics-add-button" onClick={handleAddTopicClick}>
                Add Topic
              </button>
            </div>
          </div>
          <div className="topics-table">
            <table>
              <thead>
                <tr>
                  <th>Class Name</th>
                  <th>Topic</th>
                  <th>Subject Name</th>
                  <th>Lesson Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {topics.map(topic => (
                  <tr key={topic.topicId}>
                    <td>{topic.className}</td>
                    <td>{topic.topic}</td>
                    <td>{topic.subjectName}</td>
                    <td>{topic.lessionName}</td>
                    <td>
                      <button  className="lessons-action-button edit" onClick={() => handleEdit(topic.topicId)}>Edit</button>
                      <button className="lessons-action-button delete" onClick={() => handleDelete(topic.topicId)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default Topics;
