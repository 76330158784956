import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './DueDetails.css';

const DueDetails = () => {
  const { className } = useParams();
  const [classData, setClassData] = useState({ list: [] });
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('session'); // 'session' or 'month'

  useEffect(() => {
    const fetchDues = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/TeacherDashboard/DuesRecord_All', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
          },
          body: JSON.stringify({
            sessionId: 0,
          }),
        });
        const data = await response.json();
        setClassData(data);
      } catch (error) {
        console.error('Error fetching dues:', error);
      }
    };

    fetchDues();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = classData.list.filter((due) =>
    due.studentName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    due.admissionNo.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="due-details">
      <div className="tabs">
        <button
          className={`tab ${activeTab === 'session' ? 'active' : ''}`}
          onClick={() => setActiveTab('session')}
        >
          Current Session
        </button>
        <button
          className={`tab ${activeTab === 'month' ? 'active' : ''}`}
          onClick={() => setActiveTab('month')}
        >
          Current Month
        </button>
      </div>

      <h1>
        {activeTab === 'session'
          ? `Due Details for Current Session`
          : `Current Month's Due Details`}
      </h1>

      <div className="search-export-container">
        <input
          type="text"
          className="search-bar"
          placeholder="Search by Student Name or Admission No."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button className="export-button">Export</button>
      </div>

      {activeTab === 'session' ? (
        <table className="due-detail-table">
          <thead>
            <tr>
              <th>Serial No.</th>
              <th>Admission No.</th>
              <th>Student Name</th>
              <th>Mobile No.</th>
              <th>Payment month</th>
              <th>Paid Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((due, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{due.admissionNo}</td>
                <td>{due.studentName}</td>
                <td>{due.mobileNo}</td>
                <td>{due.paymentDate}</td>
                <td>{due.paidAmount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <table className="due-detail-table">
          <thead>
            <tr>
              <th>Serial No.</th>
              <th>Admission No.</th>
              <th>Student Name</th>
              <th>Mobile No.</th>
              <th>Payment Month</th>
              <th>Paid Amount</th>
            </tr>
          </thead>
          <tbody>
         
          </tbody>
        </table>
      )}
    </div>
  );
};

export default DueDetails;
