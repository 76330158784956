import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ViewAnnouncements.css'; // Ensure you create this CSS file for styling
import Navbar from './Navbar';

const ViewAnnouncements = () => {
  const [announcements, setAnnouncements] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch announcements from the API
    const fetchAnnouncements = async () => {
      try {
        const response = await fetch('https://arizshad-002-site5.ktempurl.com/api/Anouncement/All_Anouncement_Admin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
            // Add any required headers here, e.g., authorization token
          },
          body: JSON.stringify({
            // Include any necessary body parameters here if required by the API
          })
        });

        const data = await response.json();
        // Map the API response to the state format
        const mappedAnnouncements = data.list.map((announcement, index) => ({
          id: index + 1, // or use any unique id from the API
          class: `Class ${announcement.classId}`, // Modify based on your classId mapping
          section: `Section ${announcement.sectionId}`, // Modify based on your sectionId mapping
          title: announcement.title,
          message: announcement.message,
          createdOn: announcement.createdOn,
          createdBy: announcement.createdBy,
          createdFor: announcement.createdFor
        }));
        setAnnouncements(mappedAnnouncements);
      } catch (error) {
        console.error('Error fetching announcements:', error);
      }
    };

    fetchAnnouncements();
  }, []);

  const handleCreateAnnouncement = () => {
    navigate('/announcements'); // Navigate to the Announcement page
  };

  return (
    <div className="view-announcements">
      
      <div className="header">
        <h2 className="view-announcements-header">View Announcements</h2>
        <button className="create-announcement-button" onClick={handleCreateAnnouncement}>
          Create Announcement
        </button>
      </div>

      <div className="announcements-list">
        {announcements.map((announcement) => (
          <div key={announcement.id} className="announcement-card">
            <div className="announcement-header">
              <span className="announcement-class">Class: {announcement.class}</span>
              <span className="announcement-section">Section: {announcement.section}</span>
            </div>
            <h3 className="announcement-title">{announcement.title}</h3>
            <p className="announcement-message">{announcement.message}</p>
            <div className="announcement-details">
              <span><strong>Created On:</strong> {announcement.createdOn}</span>
              <span><strong>Created By:</strong> {announcement.createdBy}</span>
              <span><strong>Created For:</strong> {announcement.createdFor}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ViewAnnouncements;
